<template>
  <div class="animated fadeIn">
    
    <!--
    <b-button variant="danger" :active="true" v-on:click="Monthly()">Monthly</b-button>
    <b-button variant="primary" :active="true" v-on:click="Weekly()">Weekly</b-button>
    <b-button variant="success" :active="true" v-on:click="Daily()">Daily</b-button>
    -->
      <b-row>
      <!-- <b-col cols="12" md="2"> -->
      <b-col cols="6" md="6" lg="6" xl="2">
        <b-card no-body class="bg-primary">
          <b-card-body class="pb-0">
            <h4 class="mb-0">{{totalAmount | formatNumber}}</h4>
            <p>{{ $t("message.revenue") }}</p>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="6" md="6" lg="6" xl="2">
        <b-card no-body class="bg-info">
          <b-card-body class="pb-0">
            <h4 class="mb-0">{{totalQty | formatNumber}}</h4>
            <p>{{ $t("message.transactions") }}</p>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="6" md="6" lg="6" xl="2">
        <b-card no-body class="bg-success">
          <b-card-body class="pb-0">
            <h4 class="mb-0">{{OnlineMachine}}</h4>
            <p>{{ $t("message.online") }}</p>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="6" md="6" lg="6" xl="2">
        <b-card no-body class="bg-danger">
          <b-card-body class="pb-0">
            <h4 class="mb-0">{{OfflineMachine}}</h4>
            <p>{{ $t("message.offline") }}</p>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="6" md="6" lg="6" xl="2" v-if="user.credit.type === 'limit'">
        <b-card no-body class="bg-warning">
          <b-card-body class="pb-0">
            <h4 class="mb-0">{{user.credit.value | formatNumber}}</h4>
            <p>{{ $t("message.creditBalance") }}</p>
          </b-card-body>
        </b-card>
      </b-col>
      
      <b-col cols="6" md="6" lg="6" xl="2">
        <b-card no-body class="bg-secondary">
          <b-card-body class="pb-0">
            <h4 class="mb-0">{{user.credit.valueSMS | formatNumber}}</h4>
            <p>{{ $t("message.smsCredit") }}</p>
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>

    <b-row>
      <b-col cols="12" md="12">
        <b-card>
          <bar-daily :chart-data="chartData" :height="260"></bar-daily>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-card header="Notification">
      <div class="table-responsive">
        <table class="table2">
          <thead style="text-align-last: center">
            <th>Level</th>
            <th>Timestamp</th>
            <th>Machine ID</th>
            <th>Event</th>
          </thead>
          <template v-for="item in notification">
            <tr style="text-align: center">
              <template v-if="item.level === 'WARN'">
                <td width="15%">
                  <span class="badge badge-warning" style="font-size: 100%">WARN</span>
                </td>
              </template>
              <template v-else-if="item.level === 'INFO'">
                <td>
                  <span class="badge badge-success" style="font-size: 100%">INFO</span>
                </td>
              </template>
              <template v-else-if="item.level === 'ERROR'">
                <td>
                  <span class="badge badge-danger" style="font-size: 100%">ERROR</span>
                </td>
              </template>
              <template v-else-if="item.level === 'FATAL'">
                <td>
                  <span class="badge badge-secondary" style="font-size: 100%">FATAL</span>
                </td>
              </template>
              <td>{{item.timeStamp | moment("YYYY-MM-DD HH:mm")}}</td>
              <td>{{item.machineId}}</td>
              <td>{{item.event}}</td>
            </tr>
          </template>
        </table>
      </div>
    </b-card>-->

    <b-row>
      <b-col cols="12">
        <b-card>
          <apexchart type="bar" height="200" :options="transactionChartsBar.options" :series="transactionChartsBar.series"></apexchart>
        </b-card>
      </b-col>
      <b-col sm="5" md="6">
        <b-card>
          <apexchart type="donut" :options="transactionChartsDonut.options" :series="transactionChartsDonut.series"></apexchart>
        </b-card>
      </b-col>
      <b-col sm="5" md="6">
        <b-card>
          <apexchart type="donut" :options="deviceStatusChartsDonut.options" :series="deviceStatusChartsDonut.series"></apexchart>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import BarDaily from './charts_dash/BarDaily'
import webServices from '../script'
import moment from 'moment'

//https://stackoverflow.com/questions/44538110/how-to-format-numbers-in-vuejs
var numeral = require('numeral')

Vue.filter('formatNumber', function (value) {
  return numeral(value).format('0,0') // displaying other groupings/separators is possible, look at the docs
})

export default {
  name: 'dashboard',
  components: {
    BarDaily
  },
  data() {
    return {
      user: JSON.parse(window.localStorage.getItem('users')),
      role_id: window.localStorage.getItem('roleID'),
      isMonthly: true,
      isWeekly: false,
      isDaily: false,
      fromdate: new Date(),
      todate: new Date(),
      notification: [],
      chartData: [],
      params: {
        mode: 'weekly'
      },
      searchData: {
        start: '',
        end: '',
        productId: ''
      },
      totalAmount: 0,
      totalQty: 0,
      EmptySlots: 0,
      OnlineMachine: 0,
      OfflineMachine: 0,
      series: [74, 55, 13],
      chartOptions: {
        labels: ['ซักผ้า', 'อบผ้า', 'ขายของ']
        // colors: ['#3335ff', 'blue', 'green']
      },
      transactionChartsDonut: {
        series: [],
        options: {
          labels: []
        }
      },
      deviceStatusChartsDonut: {
        series: [],
        options: {
          labels: []
        }
      },
      transactionChartsBar: {
        series: [],
        options: {
          chart: {
            stacked: true,
            stackType: '100%'
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          title: {
            text: this.$t('message.amount')
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          legend: {
            position: 'top',
            horizontalAlign: 'center',
            offsetX: 40
          },
          xaxis: {
            categories: [this.$t('message.all')]
          },
          colors: ['#008FFB', '#FEB019']
        }
      }
    }
  },
  mounted() {
    this.fromdate.setHours(0, 0, 0, 0)
    this.todate.setHours(23, 59, 59, 999)
    this.fromdate = moment(this.fromdate).format('YYYY-MM-DD HH:mm:ss')
    this.todate = moment(this.todate).format('YYYY-MM-DD HH:mm:ss')
    this.getEventData(1, 10, '', '', '', '', this.fromdate, this.todate)
    this.getChartsReport()
    this.getUserReport()
    this.getMachineStatus()
    this.getSlots()
    this.getCharts()
  },
  methods: {
    isAdmin(role) {
      return webServices.isAdmin(role)
    },
    getEventData(page, row, machineid, eventid, event, level, from, to) {
      this.$Progress.start()
      webServices
        .getEvent(page, row, machineid, eventid, event, level, from, to)
        .then(res => {
          this.$Progress.finish()
          this.notification = res.data.rows
          // console.log(res.data)
        })
        .catch(err => {
          // alert('หมดเวลาการเชื่อมต่อ กรุณาเข้าสู่ระบบใหม่อีกครั้ง')
          this.$Progress.fail()
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          console.log('error Event @DASHBOARD')
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    getChartsReport() {
      this.$Progress.start()
      webServices
        .getChartsUserReport(this.params)
        .then(res => {
          this.$Progress.finish()
          this.chartData = res.data
          // console.log(res.data)
        })
        .catch(err => {
          this.$Progress.fail()
          console.log('error @Get charts')
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    getUserReport() {
      this.$Progress.start()
      webServices
        .getReportByUserDashboard(this.searchData)
        .then(res => {
          this.$Progress.finish()
          // console.log(res.data)
          this.totalAmount = res.data.totalAmount
          this.totalQty = res.data.totalQty
        })
        .catch(err => {
          this.$Progress.fail()
          console.log('error @dashboard report by user')
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    getSlots() {
      webServices
        .getMachineSlots()
        .then(res => {
          // console.log(res.data)
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].qty <= 0) {
              this.EmptySlots++
            }
          }
        })
        .catch(err => {
          console.log('error @dashboard machineslots')
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    getMachineStatus() {
      webServices
        .getMachineStatus()
        .then(res => {
          // console.log(res.data)
          const now = new Date()
          for (var i = 0; i < res.data.length; i++) {
            if (now - new Date(res.data[i].lastOnline) <= 1800000) {
              this.OnlineMachine++
            } else {
              this.OfflineMachine++
            }
          }
        })
        .catch(err => {
          console.log('error @dashboard machinestatus')
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    getCharts() {
      webServices.getDonutChartTransaction({ mode: 'prices' }).then(res => {
        const data = res.data
        if (data.success) {
          this.transactionChartsDonut.series.push(data.chart.washSum, data.chart.vendingSum, data.chart.dryerSum)
          this.transactionChartsDonut.options.labels.push(this.$t('message.wash'), this.$t('message.sell'), this.$t('message.dry'))
        }
      })

      webServices.getDonutChartTransaction({ mode: 'qty' }).then(res => {
        const data = res.data
        if (data.success) {
          this.transactionChartsBar.series.push(
            {
              name: this.$t('message.wash'),
              data: [data.chart.washSum]
            },
            {
              name: this.$t('message.dry'),
              data: [data.chart.dryerSum]
            }
          )
        }
      })

      webServices.getDonutDeviceStatus().then(res => {
        const data = res.data
        if (data.success) {
          this.deviceStatusChartsDonut.series.push(data.chart.ready, data.chart.process, data.chart.error, data.chart.disable)
          this.deviceStatusChartsDonut.options.labels.push(this.$t('message.ready'), this.$t('message.washing'), this.$t('message.error'), this.$t('message.disable'))
        }
      })
    }
  }
}
</script>