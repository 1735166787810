<script>
import { Bar, mixins } from 'vue-chartjs'
// import webServices from '../../../script'
export default {
  extends: Bar,
  props: ['chartData'],
  mixins: [mixins.reactiveProp],
  data () {
    return {
      label: [],
      dataset: [],
      params: {
        mode: 'weekly',
        machineId: this.machineId
      },
      options: {
        responsive: true,
      maintainAspectRatio: false
      }
    }
  },
  methods: {
    createCharts () {
      console.log('create charts')
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted () {
    this.createCharts()
  }
}
</script>